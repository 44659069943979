import React, { useRef, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import Headroom from "headroom.js";
import Scroll from "./utils/Scroll";

import { Helmet } from "react-helmet";
// Layouts

// Views
import Home from "./views/Home";
import Form from "./views/Form";

const App = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <title>A-1 Sewer Service</title>
        <meta name="description" content="For regular maintenance and pumping, call or text now. We are available 24/7 for septic emergencies." />
        <script src="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=places&key=AIzaSyAmULVU7wA2kSa6drcsUhXNtyu6Oza-fbs"></script>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"></meta>
      </Helmet>

      <Router>
        <Scroll>
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route path="/*" element={<Home />}></Route>
          </Routes>
        </Scroll>
      </Router>
    </>
  );
};

export default App;
