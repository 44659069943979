import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Headroom from "react-headroom";
import Container from "react-bootstrap/Container";
import logo from "../img/A-1.png";

const Header = (props) => {
  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".headroom");
    const scrollTop = window.scrollY;
    scrollTop >= 250 ? header.classList.add("is-sticky") : header.classList.remove("is-sticky");
  };
  return (
    <Headroom>
      <div>
              <div className="zero-nav d-block">
      <i className="icomoon icon-phone"></i> <a href="763-434-3686">763-434-3686</a>
      </div>
      {["md"].map((expand) => (
        
        <Navbar key={expand} bg="light" expand={expand}>

          <Container>
            <span class="brand_logo">
              <Link to="/">
                <img className="logo" src={logo} style={{ width: "170px" }} />
              </Link>
            </span>
            <div className="justify-content-end flex-grow-1 pe-3 px-2 ml-auto phone hidden-sm">
            <p className="mb-0">
            <i className="icomoon icon-phone"></i> <a href="763-434-3686">763-434-3686</a>
            </p>
            </div>

            {/*props.nav && (
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`}>
                <i style={{ fontSize: "1.5em" }} class="icon-stream"></i>
              </Navbar.Toggle>
            )*/}
            {/*<Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>A-1 Sewer Service</Offcanvas.Title>
            </Offcanvas.Header>
              props.nav && (
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 px-2">
                    <Nav.Link aria-controls={`offcanvasNavbar-expand-${expand}`} href="/#services">
                      Services
                    </Nav.Link>
                    <Nav.Link href="/#pricing">Pricing</Nav.Link>
                    <Nav.Link href="/#about">About</Nav.Link>
                    <Nav.Link className="theme-element theme-element--accent btn" href="/booking">
                      Book Your Service
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              )
            </Navbar.Offcanvas>
              */}
          </Container>
        </Navbar>
      ))}
      </div>
    </Headroom>
  );
};

export default Header;
